import React from "react";
import { graphql, PageProps } from "gatsby";
import WordPressPreview from "components/WordPressPreview/WordPressPreview";
import { ProjectByIdQuery } from "codegen";
import { Loader, Hero } from "components/ACF";
import { Layout, PostNavigation } from "components";

import "./project-single.scss";

interface CPTPageContext {
  id: string;
  title: string;
  cptType: string;
}

const ProjectSingle = ({
  data: { project, next, previous },
}: PageProps<ProjectByIdQuery, CPTPageContext>): JSX.Element => {
  const { title, acfLayout, content, seo, featuredImage } = project;

  return (
    <Layout pageTitle={title} seo={seo}>
      <>
        <Hero
          data={{
            content: `<h1>${title}</h1>`,
            compact: true,
            breadcrumbs: "below",
            image: featuredImage?.node,
          }}
          location={seo.breadcrumbs}
        />
        {acfLayout?.layout?.length > 0 ? (
          <Loader
            data={acfLayout.layout.map(({ ...layout }) => ({
              ...layout,
            }))}
            location={seo?.breadcrumbs}
          />
        ) : (
          content && (
            <div className="layout">
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          )
        )}
        <PostNavigation prevLink={previous} nextLink={next} backLink="/projects/" backLinkText="All projects" />
      </>
    </Layout>
  );
};

export default WordPressPreview({ postType: "projects" })(ProjectSingle);

export const CPTQueryById = graphql`
  query ProjectById($id: String!, $previousPostId: String, $nextPostId: String) {
    # selecting the current page by id
    project: wpProject(id: { eq: $id }) {
      ...ProjectFields
    }
    previous: wpProject(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpProject(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`;
